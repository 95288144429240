import React, { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Container,
  // Image,
  Button,
  Card,
} from "react-bootstrap";
// import { BsArrowLeftShort } from "react-icons/bs";
import { BiFile } from "react-icons/bi";
// import { FiMenu } from "react-icons/fi";
// import {
//   domaine_st_come,
//   domian_pine_hill_logo,
//   phase1,
//   land,
// } from "../../assets/images";
import "./style.scss";
import {
  ref,
  onValue,
  // set,
  orderByChild,
  equalTo,
  query,
} from "firebase/database";
import { dealsRef, rdb, tables } from "../../services/firebase";
import { constant } from "../../utils/constant";
// import {
//   GET_COMPANY_BY_ID,
//   GET_CONTACT_BY_ID,
//   GET_CONTACTS_OF_COMPANY,
// } from "../../utils/config";
import { remainingTime } from "../timer";
import { SubmitDetails, About, Spinner } from "../../components";
import strings from "../../utils/fr.json";
import { Header } from "../index";
import { filterString } from "../../utils/helper";

const Promises = (props) => {
  const {
    show,
    // togglePromiseModal,
    closePromiseModal,
  } = props;
  // var checkMap = process.env.REACT_APP_MAP === constant.pinehill;
  const [promisesData, setPromisesData] = useState([]);
  const [dealsObj, setDealsObj] = useState(null);
  const [isSubmitDetails, setIsSubmitDetails] = useState(false);
  const [aboutModal, setAboutModal] = useState(false);
  const [isCompanyEdit, setIsCompanyEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeRemain, setIsTimeRemain] = useState(false);
  const [totalDealsData, setTotalDeals] = useState([]);

  // Envoyée (submitted)
  // En cours (in progress)
  // Acceptée (accepted)

  // var stageValue = constant.dealsStage.pinehill;
  // var submitted = [
  //   stageValue.phase1.purchaseSend,
  //   stageValue.phase2.purchaseSend,
  //   stageValue.phase3.purchaseSend,
  //   stageValue.phase4.purchaseSend,
  // ];
  // var inProgress = [
  //   stageValue.phase1.purchaseSent,
  //   stageValue.phase2.purchaseSent,
  //   stageValue.phase3.purchaseSent,
  //   stageValue.phase4.purchaseSent,
  // ];
  // var accepted = [
  //   stageValue.phase1.purchaseAccept,
  //   stageValue.phase2.purchaseAccept,
  //   stageValue.phase3.purchaseAccept,
  //   stageValue.phase4.purchaseAccept,
  // ];

  useEffect(() => {
    // if (show) {
    // getPromises();
    // }
    async function fetchData() {
      var userID = await localStorage.getItem("@userId");
      setLoading(true);
      onValue(dealsRef, async (snapshot) => {
        const newDealsData = snapshot.val();
        var dealsData = await Object.values(newDealsData);
        var findpromises = await query(
          ref(rdb, "/promises"),
          orderByChild("userID"),
          equalTo(userID)
        );
        onValue(findpromises, async (snapshot) => {
          const newPromisesList = snapshot.val();
          var promisesDatas = newPromisesList
            ? Object.entries(newPromisesList)
            : [];
          promisesDatas = promisesDatas.filter(
            (item) => item[1].map === process.env.REACT_APP_MAP
          );
          setTotalDeals(dealsData);
          setPromisesData(promisesDatas);
          setLoading(false);
        });
      });
    }
    return fetchData();
  }, [show]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTimeRemain(remainingTime());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleModify = async (data) => {
    if (data.contactID) {
      let findContact = await query(
        ref(rdb, "/contacts"),
        orderByChild("vid"),
        equalTo(data.contactID)
      );
      onValue(
        findContact,
        async (snapshot) => {
          const newContactList = snapshot.val();
          let contactData = newContactList
            ? Object.entries(newContactList)
            : [];
          let contactObjData = contactData[0][1];
          let obj = {
            address: contactObjData?.address || "",
            name: contactObjData.name,
            phone: contactObjData.phoneNumber,
            email: contactObjData?.email,
            dealId: data.dealID,
            contactID: data.contactID,
          };
          setDealsObj(obj);
          setIsCompanyEdit(false);
          // togglePromiseModal();
          setTimeout(() => {
            setIsSubmitDetails(true);
          }, 500);
        },
        { onlyOnce: true }
      );
    } else {
      let findCompany = await query(
        ref(rdb, "/companies"),
        orderByChild("companyId"),
        equalTo(data.companyID)
      );
      onValue(findCompany, async (snapshot) => {
        const newCompanyList = await snapshot.val();
        let companyData = newCompanyList ? Object.entries(newCompanyList) : [];
        let companyObjData = companyData[0][1];
        let findContact = await query(
          ref(rdb, "/contacts"),
          orderByChild("vid"),
          equalTo(companyObjData.contactVids[0])
        );
        onValue(
          findContact,
          async (snapshot) => {
            const newContactList = snapshot.val();
            let contactData = newContactList
              ? Object.entries(newContactList)
              : [];
            let contactObjData = contactData[0][1];

            let obj = {
              address: contactObjData?.address || "",
              name: companyObjData?.description,
              phone: companyObjData.phone,
              email: contactObjData?.email,
              represent: companyObjData.name,
              dealId: data.dealID,
              companyID: data.companyID,
              contactID: companyObjData.contactVids[0],
            };
            setDealsObj(obj);
            setIsCompanyEdit(true);
            // togglePromiseModal();
            setTimeout(() => {
              setIsSubmitDetails(true);
            }, 500);
          },
          { onlyOnce: true }
        );
      });
    }
  };

  return (
    <>
      <Spinner loading={loading} />
      <Modal
        className="promises"
        show={show}
        fullscreen={true}
        onHide={closePromiseModal}
      >
        <Modal.Header>
          <Container>
            <Row>
              <Header
                // handleMenu={() => setIsMenuPage(!isMenuPage)}
                // isMainMap
                isBack
                handleBack={closePromiseModal}
                openPromise
                style={{ position: "unset" }}
                // isMenuPage={isMenuPage}
                // handleLogout={handleLogout}
              />
              {/* <Col xl={5} md={4} sm={4} xs={4}>
                <div className="col-first">
                  <BsArrowLeftShort
                    onClick={togglePromiseModal}
                    className="back-btn"
                  />
                </div>
              </Col>
              <Col xl={2} md={4} sm={4} xs={4}>
                <div className="col-second">
                  <Image
                    src={checkMap ? domian_pine_hill_logo : domaine_st_come}
                    alt="logo"
                    fluid
                  />
                </div>
              </Col>
              <Col xl={5} md={4} sm={4} xs={4}>
                <div className="col-third">
                  <FiMenu className="toggle-btn" />
                </div>
              </Col> */}
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="top">
              <h5 className="mb-0">{strings.Promises.promise}</h5>
              <p className="mb-0">{`(${promisesData.length} ${strings.Promises.promise})`}</p>
            </div>
            <div className="promises-list">
              <Row>
                {promisesData.map((item, index) => {
                  var data = item[1];
                  var dealsData = totalDealsData.find(
                    (d) => d.dealId === data.dealID
                  );

                  // Temporary hide
                  // var statusValue =
                  //   dealsData?.properties?.statut_des_promesses_d_achat;
                  // var status = statusValue
                  //   ? statusValue === strings.Status.row1 ||
                  //     statusValue === strings.Status.row2
                  //     ? constant.status.submitted
                  //     : constant.status.accepted
                  //   : "";
                  var status = constant.status.submitted;

                  // var status = submitted.includes(
                  //   dealsData?.properties.dealstage.value
                  // )
                  //   ? constant.status.submitted
                  //   : inProgress.includes(dealsData?.properties.dealstage.value)
                  //   ? constant.status.inProcess
                  //   : constant.status.accepted;
                  var styles =
                    status === constant.status.submitted
                      ? "submitted"
                      : status === constant.status.inProcess
                      ? "in-process"
                      : "accepted";
                  if (dealsData) {
                    const identification = filterString(
                      dealsData?.properties?.identification_du_lot_ph?.value
                    );
                    const identificationTitle = identification
                      ?.substr(4, 1)
                      .toString();
                    return (
                      <Col key={index.toString()} xs={12}>
                        <Card className="promises-single">
                          <Card.Header className="d-sm-flex d-block">
                            <h5 className="item-name mb-sm-0 mb-2">
                              {`${
                                identification.includes("PH4")
                                  ? identificationTitle === "C"
                                    ? "Chalet"
                                    : identificationTitle === "M"
                                    ? "Mini Chalet"
                                    : "Dome"
                                  : "Terrain"
                              } ${identification || ""}`}

                              {/* {`Terrain ${dealsData?.properties.dealname?.value}`} */}
                            </h5>
                            <p className="dimension">{`${dealsData?.properties.nombre_de_pi2?.value}Pi²`}</p>
                          </Card.Header>
                          <Card.Body>
                            <div className="card-bottom">
                              <Row>
                                {status ? (
                                  <Col sm={6} className="mb-4 mb-sm-0">
                                    <div className={`status ${styles}`}>
                                      {status}
                                    </div>
                                  </Col>
                                ) : null}
                                <Col sm={6}>
                                  <Button
                                    className="modify-btn"
                                    variant={
                                      // "grey"
                                      !timeRemain &&
                                      status &&
                                      constant.status.submitted
                                        ? "green"
                                        : "grey"
                                      // status === constant.status.accepted ||
                                      // status === constant.status.inProcess
                                      //   ? "grey"
                                      //   : "green"
                                    }
                                    disabled={
                                      // true
                                      timeRemain ||
                                      !constant.status.submitted ||
                                      dealsData?.properties
                                        ?.statut_des_promesses_d_achat
                                        ?.value === strings.Status.complete
                                        ? true
                                        : false
                                      // status &&
                                      // (status === constant.status.accepted ||
                                      //   status === constant.status.inProcess)
                                    }
                                    onClick={() => handleModify(data)}
                                  >
                                    <BiFile className="me-2" />
                                    {strings.Promises.modifyForm}
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  }
                  return null;
                })}
              </Row>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
      <SubmitDetails
        // show={true}
        // activeArea={activeArea}
        isEdit={true}
        isCompanyEdit={isCompanyEdit}
        show={isSubmitDetails}
        dealsData={dealsObj}
        handleSubmitDetails={() => setIsSubmitDetails(!isSubmitDetails)}
        handleTerrains={() => {}}
        handleAbout={() => setAboutModal(!aboutModal)}
      />
      <About show={aboutModal} handleAbout={() => setAboutModal(!aboutModal)} />
    </>
  );
};

export default Promises;
